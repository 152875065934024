<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const { t } = useI18n();
  const jobsSearchStore = useJobsSearchStore();

  const searchResultsTitle = computed(
    () => jobsSearchStore.searchItem?.title ?? t('SEARCH_PAGE.SEARCH_RESULT_LABEL'),
  );
  const isLoading = computed(
    () => jobsSearchStore.loadState === 'inProgress' && jobsSearchStore.page === 1,
  );
</script>

<template>
  <MpText
    v-if="!isLoading"
    as="h1"
    type="h4"
    regular
    style="margin-top: var(--mp-space-90); margin-bottom: var(--mp-space-50)"
  >
    {{ searchResultsTitle }} <b>({{ jobsSearchStore.totalItems }})</b>
  </MpText>
</template>
