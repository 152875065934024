<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <MpText
    v-if="jobCard.job?.description"
    as="p"
    no-margin
    style="white-space: unset"
  >
    {{ handleNewline(jobCard.job.description) }}
  </MpText>
</template>
