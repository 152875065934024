<script setup lang="ts">
  definePageMeta({
    middleware: ['search'],
  });

  const { t } = useI18n();
  const jobsSearchStore = useJobsSearchStore();

  const searchPageTitle = computed(
    () => jobsSearchStore.searchItem?.title ?? t('SEARCH_PAGE.TITLE'),
  );

  useHead({
    title: searchPageTitle,
    meta: [{ name: 'description', content: t('SEARCH_PAGE.DESCRIPTION') }],
  });

  useSeoMeta({
    ogTitle: searchPageTitle,
    ogDescription: t('SEARCH_PAGE.DESCRIPTION'),
  });

  onBeforeRouteLeave(() => jobsSearchStore.cleanFilters());
</script>

<template>
  <div>
    <JobSearchBar show-filters />
    <JobSearchTitle />
    <JobSearchSave />
    <JobSearchResults />
  </div>
</template>
