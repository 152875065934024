<script setup lang="ts">
  import { MpCard, MpIcon, MpText } from '@borg/ui';
  import { JobDetailsSource, JobGroup } from '@borg/types';

  const props = defineProps<{
    item: JobGroup;
    source?: JobDetailsSource;
  }>();

  const jobCard = useJobCard.provide();
  jobCard.set(props.item, props.source);
  const organizationCard = useOrganizationCard.provide();
  organizationCard.set(props.item.organization);
</script>

<template>
  <MpCard
    class="job-card"
    border="light-only"
  >
    <div class="job-card__content">
      <JobCardV6Tags />
      <LinksJob :job="item.jobs[0]">
        <MpText
          as="h5"
          link-card
        >
          {{ jobCard.job?.title }}
        </MpText>
      </LinksJob>
      <JobCardSubtitle />
      <div class="job-card__row">
        <MpIcon
          class="job-card__icon"
          name="pin"
        />
        <JobCardLocation />
      </div>
      <div
        v-if="jobCard.job?.endsAt"
        class="job-card__row"
      >
        <MpIcon
          class="job-card__icon"
          name="calendar"
        />
        <MpText
          as="time"
          no-margin
          :datetime="jobCard.job.endsAt"
        >
          {{ $d(...localeDateTimeFormat(jobCard.job.endsAt, 'dmy')) }}
        </MpText>
      </div>
    </div>
    <div
      v-if="item.externalUrl"
      class="poslovac"
    >
      <LinksLocale
        :to="item.externalUrl"
        target="_blank"
        class="poslovac__link"
      >
        <Illustration image="poslovac" />
        <MpText
          semibold
          no-margin
          link
        >
          Poslovac
        </MpText>
      </LinksLocale>
    </div>
  </MpCard>
</template>

<style scoped lang="scss">
  .job-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--mp-space-60);
    background: rgba(219, 247, 242, 0.1);
    border-color: var(--mp-color-border-c-30);

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--mp-space-30);
    }

    &__row {
      display: flex;
      gap: var(--mp-space-30);
      align-items: center;
    }

    &__icon {
      color: var(--mp-color-system-e-dark);
    }
  }

  .poslovac {
    display: flex;
    justify-content: flex-end;
    color: var(--mp-color-system-e-dark);

    &__link {
      display: flex;
      align-items: center;
      gap: var(--mp-space-30);
    }
  }
</style>
