<script setup lang="ts">
  import { JobDetails } from '@borg/types';

  const jobCard = useJobCard.inject();

  const tags = computed(() => {
    let _tags: JobDetails['tags'] = [];
    if (jobCard.job?.salary) {
      const salaryText = getSalaryString(
        jobCard.job.salary.from,
        jobCard.job.salary.to,
        jobCard.job.salary.currency,
      );
      if (salaryText) {
        _tags.push({ key: 'salary', name: salaryText });
      }
    }
    if (jobCard.job?.tags?.length) {
      const restOfTags = jobCard.job.tags.map((tag) => {
        return {
          key: 'custom',
          name: tag,
        };
      });
      _tags = [..._tags, ...restOfTags];
    }
    return _tags;
  });
</script>

<template>
  <div
    v-if="tags.length"
    class="tags"
  >
    <JobTags
      :tags="tags"
      no-margin
    />
  </div>
</template>

<style scoped lang="scss">
  .tags {
    display: flex;
    gap: var(--mp-space-30);
    flex-wrap: wrap;

    --mp-color-system-a-light: #fffcf1;
    --mp-color-system-a: #ffcb3b;
    --mp-color-system-a-dark: #ffbb00;
    --mp-color-system-b-light: #fff1eb;
    --mp-color-system-b: #fd7e40;
    --mp-color-system-b-dark: #ca6533;
    --mp-color-system-c-light: #dbf7f2;
    --mp-color-system-c: #4dd7be;
    --mp-color-system-c-dark: #3eac98;

    @include dark-mode {
      --mp-color-system-a-light: #fffcf1;
      --mp-color-system-a: #ffcb3b;
      --mp-color-system-a-dark: #ffbb00;
      --mp-color-system-b-light: #28303c;
      --mp-color-system-b: #fd7e40;
      --mp-color-system-b-dark: #ca6533;
      --mp-color-system-c-light: #28303c;
      --mp-color-system-c: #4dd7be;
      --mp-color-system-c-dark: #3eac98;
    }
  }
</style>
