<script setup lang="ts">
  import { MpInfiniteScroll } from '@borg/ui';

  const route = useRoute();
  const jobsSearchStore = useJobsSearchStore();
  const jobsSearchWideStore = useJobsSearchWideStore();
  const {
    data: recommendedJobs,
    status: recommendedJobsStatus,
    execute: getRecommendedJobs,
    clear: clearRecommendedJobs,
  } = recommendedJobsRepository.getMany('search-page', true, true, false);

  const isSearchLoading = computed(
    () =>
      jobsSearchStore.loadState === 'inProgress' ||
      jobsSearchWideStore.loadState === 'inProgress' ||
      recommendedJobsStatus.value === 'pending',
  );

  function loadMore() {
    if (jobsSearchStore.loadState === 'isSuccess' && !jobsSearchStore.allResultsListed) {
      jobsSearchStore.searchNextValues();
    } else if (jobsSearchStore.allResultsListed && jobsSearchWideStore.loadState === 'isInitial') {
      initWideJobs();
    } else if (
      jobsSearchWideStore.loadState === 'isSuccess' &&
      !jobsSearchWideStore.allResultsListed
    ) {
      jobsSearchWideStore.searchNextValues();
    } else if (
      jobsSearchWideStore.allResultsListed &&
      recommendedJobsStatus.value !== 'pending' &&
      recommendedJobsStatus.value !== 'success'
    ) {
      loadRecommendedJobs();
    }
  }

  const initWideJobs = async () => {
    if (jobsSearchStore.allResultsListed) {
      await jobsSearchWideStore.lazyInit(route.query);
    }
  };

  const loadRecommendedJobs = async () => {
    if (
      recommendedJobsStatus.value === 'idle' &&
      recommendedJobs.value &&
      recommendedJobs.value.length > 0
    ) {
      return;
    }
    if (
      jobsSearchWideStore.allResultsListed &&
      recommendedJobsStatus.value !== 'pending' &&
      recommendedJobsStatus.value !== 'success'
    ) {
      await getRecommendedJobs();
    }
  };

  await initWideJobs();
  await loadRecommendedJobs();

  // handles 0 (or small amount of) results in 'normal' or 'wide' search, to init 'next' section
  const delay = 250;
  watch(
    () => ({
      allResultsListed: jobsSearchStore.allResultsListed,
      totalPages: jobsSearchStore.totalPages,
    }),
    (value) => {
      if (value.allResultsListed && value.totalPages <= 1) {
        setTimeout(initWideJobs, delay);
      }
    },
  );
  watch(
    () => ({
      allResultsListed: jobsSearchWideStore.allResultsListed,
      totalPages: jobsSearchWideStore.totalPages,
    }),
    (value) => {
      if (value.allResultsListed && value.totalPages <= 1) {
        setTimeout(loadRecommendedJobs, delay);
      }
    },
  );

  watch(
    () => jobsSearchStore.lastQueryString,
    () => {
      jobsSearchWideStore.reset();
      clearRecommendedJobs();
    },
  );
</script>

<template>
  <MpInfiniteScroll
    :distance="300"
    :is-loading="isSearchLoading"
    class="search-results"
    @near-end="loadMore"
  >
    <JobsGridFeatured :items="jobsSearchStore.featuredJobs" />
    <JobsGridHighlighted>
      <template
        v-for="item in jobsSearchStore.highlightedJobs"
        :key="item.id"
      >
        <JobCardV1
          :item="item"
          source="search_results"
        />
      </template>
    </JobsGridHighlighted>
    <Grid columns="two">
      <JobCardV2
        v-for="item in jobsSearchStore.standardJobs"
        :key="item.id"
        :item="item"
        source="search_results"
      />
    </Grid>
    <Grid columns="two">
      <JobCardV3
        v-for="item in jobsSearchStore.normalJobs"
        :key="item.id"
        :item="item"
        source="search_results"
      />
    </Grid>
    <Grid columns="two">
      <JobCardV6
        v-for="item in jobsSearchStore.poslovacJobs"
        :key="item.id"
        :item="item"
      />
    </Grid>
    <JobResultsIlustrationHeader
      v-if="!!jobsSearchWideStore.items.length"
      image="jobs-ignore-location"
      :message="$t('SEARCH_PAGE.WIDER_LOCATION_JOBS_SECTION_TITLE')"
    />
    <Grid columns="two">
      <JobCardV3
        v-for="item in jobsSearchWideStore.items"
        :key="item.id"
        :item="item"
        source="search_results"
      />
    </Grid>
    <JobResultsIlustrationHeader
      v-if="recommendedJobs && recommendedJobs.length"
      image="jobs-recommended"
      :message="
        jobsSearchStore.items.length
          ? $t('SEARCH_PAGE.RECOMMENDED_JOBS_MESSAGE')
          : $t('SEARCH_PAGE.NO_RESULTS_MESSAGE')
      "
    />
    <Grid columns="two">
      <JobCardV3
        v-for="item in recommendedJobs"
        :key="item.id"
        :item="item"
        source="search_results_recommendations"
      />
    </Grid>
  </MpInfiniteScroll>
</template>

<style scoped lang="scss">
  .search-results > * {
    margin-bottom: var(--mp-space-50);
  }
</style>
