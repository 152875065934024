<script setup lang="ts">
  import { MpSwitch, pushToastMessage } from '@borg/ui';
  import { ifAuth } from '@/utils/user';
  import SaveSearchSuccessToastSubtitle from '@/components/SaveSearchSuccessToastSubtitle.vue';
  import { openAuthFormModal } from '@/components/Auth/AuthFormModal.vue';

  const { t } = useI18n();
  const jobsSearchStore = useJobsSearchStore();

  const isLoading = computed(
    () => jobsSearchStore.loadState === 'inProgress' && jobsSearchStore.page === 1,
  );

  const isVisible = computed(() => !isLoading.value && !jobsSearchStore.isFilterClean);

  async function saveSearch() {
    jobsSearchStore.setSearchSaved(true);
    if (jobsSearchStore.searchItem) {
      const result = await jobSearchService.save(jobsSearchStore.searchItem.id);
      if (result.isSuccess) {
        pushToastMessage({
          type: 'success',
          title: t('SEARCH_PAGE.SAVE_SEARCH_SUCCESS_TOAST.TITLE'),
          subtitle: h(SaveSearchSuccessToastSubtitle),
        });
        return;
      }
    }
    jobsSearchStore.setSearchSaved(false);
    pushToastMessage({
      type: 'alert',
      title: t('SEARCH_PAGE.SAVE_SEARCH_ERROR_TOAST.TITLE'),
      subtitle: t('SEARCH_PAGE.SAVE_SEARCH_ERROR_TOAST.SUBTITLE'),
    });
  }

  async function undoSaveSearch() {
    jobsSearchStore.setSearchSaved(false);
    if (jobsSearchStore.searchItem) {
      const success = await jobSearchService.delete(jobsSearchStore.searchItem.id);
      if (success) {
        return;
      }
    }
    jobsSearchStore.setSearchSaved(true);
    pushToastMessage({
      type: 'alert',
      title: t('SEARCH_PAGE.REMOVE_SAVED_SEARCH_ERROR_TOAST.TITLE'),
      subtitle: t('SEARCH_PAGE.REMOVE_SAVED_SEARCH_ERROR_TOAST.SUBTITLE'),
    });
  }

  function onSaveSearch(save: boolean) {
    ifAuth(save ? saveSearch : undoSaveSearch, () =>
      openAuthFormModal({
        showLogo: false,
      }),
    );
  }
</script>

<template>
  <div
    v-if="isVisible"
    class="save-search"
  >
    <MpSwitch
      :model-value="jobsSearchStore.isSearchSaved"
      class="save-search__switch"
      :label="$t('SEARCH_PAGE.SAVE_SEARCH_SWITCH_LABEL')"
      @update:model-value="onSaveSearch"
    />
  </div>
</template>

<style scoped lang="scss">
  .save-search {
    display: flex;
    gap: var(--mp-space-40);
    margin-bottom: var(--mp-space-50);
    border: 1px solid var(--mp-color-grey-200);
    padding: var(--mp-space-60) var(--mp-space-50);
    border-radius: 1em;

    @include screen-lg {
      margin-bottom: var(--mp-space-60);
      border: none;
      padding: 0;
    }

    &__switch {
      :deep(.mp-switch__label) {
        font-size: var(--mp-h6-fs);
        white-space: pre-wrap;
      }
    }
  }
</style>
