<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const { t } = useI18n();
</script>

<template>
  <MpText no-margin>
    {{ t('SEARCH_PAGE.SAVE_SEARCH_SUCCESS_TOAST.SUBTITLE_1') }}
    <LinksLocale :to="{ name: 'my-notifications' }">
      <MpText
        link
        style="color: inherit"
      >
        {{ t('SEARCH_PAGE.SAVE_SEARCH_SUCCESS_TOAST.SUBTITLE_2') }}
      </MpText>
    </LinksLocale>
  </MpText>
</template>
