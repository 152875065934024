import { JobSearch, JobSearchResult, Paginated, WithPaginationParams } from '@borg/types';

class JobSearchService {
  async getAll(params: WithPaginationParams) {
    const response = await http.get<Paginated<JobSearch>>('/me/saved-searches', { params });
    return response.data;
  }
  save = (jobSearchId: JobSearchResult['search']['id']) =>
    serviceHttpPostFactory('/me/saved-searches', { id: jobSearchId });
  async delete(id: JobSearchResult['search']['id']) {
    const response = await http.delete(`/me/saved-searches/${id}`);
    return response.status >= 200 && response.status < 300;
  }
}

export const jobSearchService = new JobSearchService();
