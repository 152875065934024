<script setup lang="ts">
  import { MpCard } from '@borg/ui';
  import { JobDetailsSource, JobGroup, JobSimple } from '@borg/types';

  const props = defineProps<{
    item: JobGroup | JobSimple;
    source?: JobDetailsSource;
  }>();

  const jobCard = useJobCard.provide();
  jobCard.set(props.item, props.source);
</script>

<template>
  <MpCard
    class="job-card"
    border="light-only"
  >
    <JobCardTags />
    <JobCardTitle />
    <JobCardSubtitle />
    <JobCardLocation />
    <JobCardDate />
    <JobCardDescription />
    <slot />
  </MpCard>
</template>

<style scoped lang="scss">
  .job-card {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-30);
  }
</style>
